.calendar__cell{
  width: 40px;
  height: 40px;
  border: 1px solid #2D2D2E;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
}
.calendar__cellCurrent{
  width: 40px;
  height: 40px;
  background: #2D2D2E;
  border: 1px solid #EBEBEB;
  border-radius: 10px;
  position: relative;
}
.date__pCurrent{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
  position: absolute;
  top: 2px;
  left: 4px;
}
.date__pCurrentEmpty{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #828282;
  position: absolute;
  top: 2px;
  left: 4px;
}
.date__p{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #2D2D2E;
  position: absolute;
  top: 2px;
  left: 4px;
}
.styles.date__pEmpty{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #2D2D2E;
  position: absolute;
  top: 2px;
  left: 4px;
}
.hour__worked{
  position: absolute;
  top: -1px;
  right: -1px;
  fill:#2D2D2E;
}
.hour__workedCurrent{
  position: absolute;
  top: -1px;
  right: -1px;
  fill:#00FBF4;
}
.future__work{
  position: absolute;
  top: -1px;
  right: -1px;
  fill:#978AFA;
}
.revenue{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2D2D2E;
  margin-top: -2px;
  justify-content: center;
}
.revenueCurrent{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  margin-top: -2px;
  justify-content: center;
}
.hoursWorked{
  margin-top: 7px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #2D2D2E;
  margin-left: 16px;
}
.hoursWorkedCurrent{
  margin-top: 7px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
  margin-left: 16px;
}
.info__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.achievementCurrent{
  width: 4px;
  height: 4px;
  left: 18px;
  top: 37px;
  background: #F2994A;
  position: absolute;
  border-radius: 10px;
}
.achievement{
  width: 4px;
  height: 4px;
  left: 18px;
  top: 37px;
  background: #2D2D2E;
  position: absolute;
  border-radius: 10px;
}
.salaryCurrent{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00FBF4;
  margin-top: -2px;
  justify-content: center;
}
.salary{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #2D2D2E;
  margin-top: -2px;
  justify-content: center;
}