@import '../../../styles/variables.scss';

.select {
  width: 100%;
  z-index: 1;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;

  &__value {
    width: 100%;
    padding: 0px 8px 0px 11px;
    height: 40px;
    box-sizing: border-box;
    background: $secondaryBgColor;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__list {
    width: inherit;
    position: absolute;
    top: 45px;
    list-style: none;
    padding: 10px 10px 0px;
    background: $secondaryBgColor;
    border-radius: 10px;
    cursor: pointer;
    z-index: 100;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

  &__list_hide {
    visibility: hidden;
  }

  &__option {
    margin-bottom: 12px;
  }

  &__option_disable {
    color: $disabledColor;
  }
}

.arrow {
  width: 15px;
  height: 7px;
  background-image: url('./assets/arrow.svg');
  background-repeat: no-repeat;
  pointer-events: none;
}

.arrow_up {
  transform: rotate(0deg);
}

.arrow_down {
  transform: rotate(180deg);
}

.arrow_right {
  transform: rotate(270deg);
}

.arrow_left {
  transform: rotate(90deg);
}
