.modal__container{
  align-items: center;
  background-color:  #202021;
  display: flex;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.close{
  border: none;
  background-color: transparent;
  margin: 30px 41px 0px;
  display: flex;
  width: 300px;
  justify-content: flex-end;
}
.title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  width: 341px;

}
.container{
  display: flex;
  gap: 40px;
  margin: 118px 20px 0px;
  flex-direction: column;
  align-items: center;
  img{
    width: 112px;
    height: 122px;
  }
}
.container__anonymously{
  margin: 158px 20px 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.title2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  margin-top: 20px;
  width: 170px;
}
.title1{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  margin-bottom: 24px;
  width: 340px;
}