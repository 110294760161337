@import '../../../styles/variables.scss';

.indicators {
  border-bottom: 0.3px solid $dividerColor;

  &__wrapper {
    padding: 22px 0 24px 0;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }

  &__block {
    width: calc((100% - 15px) / 2);
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: $secondaryBgColor;
    border-radius: 20px;
    justify-content: center;
    padding: 15px 0 15px 0;
    cursor: pointer;
  }

  &__block:active {
    transform: scale(0.9);
    transition: 0.7s all;
  }

  &__title {
    width: 105px;
    margin-bottom: 2px;
  }

  &__data {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }

  &__plan,
  &__fact {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  &__circle_plan {
    border: 2px #456b6a solid;
  }

  &__circle_fact {
    border: 2px $secondaryTextColor solid;
  }

  &__title_bonus {
    margin-bottom: 26px;
  }

  &__block_bonus {
    padding-bottom: 48px;
  }

  &__rating {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 1px solid $secondaryTextColor;
    background-color: rgba(32, 32, 33, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    margin-bottom: 10px;
  }

  &__title_rating {
    margin-bottom: 12px;
  }

  &__star {
    background: url('./assets/star.svg');
    width: 16px;
    height: 17px;
  }

  &__reviews {
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
  }
}
