.wrapper {
  align-self: center;
  width: 295px;
  display: flex;
  justify-content: center;
  height: 44px;
  align-items: center;

  iframe {
    width: 275px !important;
    padding: 0 !important;
    margin: 0px 0px !important;
    text-align: center !important;
  }
}
