.modal__container{
  height: 100%;
  width: 100%;
  background-color: rgba(45, 45, 46, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
}

.modal{
  width: 375px;
  min-height: 445px;
  background: #202021;
  padding: 24.5px 0px 12px;
}
.active{
  transform: scale(1);
  z-index: 10;
}


.close{
  margin: 0px 20px 23.5px;
  display: flex;
  justify-content: space-between;
}
.close__title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}
.button__close{
  border: none;
  background: transparent;
}


.menu__container{
  display: flex;
  flex-direction: column;
  max-height: 40vh;
  overflow-y: auto;
  margin: 0px 10px;
}


::-webkit-scrollbar{
  width: 4px;
}
  
::-webkit-scrollbar-thumb{
  background: #8B8B8B; 
  border-radius: 27px;
  border: 4px solid rgba(0, 0, 0, 0);
 }
.menu__container::-webkit-scrollbar:horizontal{
  width: 0;
  height: 0;
}

.menu__list{
  display: flex;
  justify-content: space-between;
  height: 88px;
  margin: 0px 8px;
  padding: 12px 0px;
  align-items: center;
  border-bottom: 1px solid rgba(235, 235, 235, 0.1);
  border-top: 1px solid rgba(235, 235, 235, 0.1);
}
.menu__img{
  margin-right: 24px;
}
.menu__info__container{
  display: flex;
  align-items: center;
}
.menu__info{
  display: flex;
  flex-direction: column;
  gap: 10px;

}
.menu__name{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #EBEBEB;
}
.menu__quantity{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #BDBDBD;
}
.menu__price{
  margin-bottom: 25px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  color: #EBEBEB;
}
.sum__container{
  display: flex;
  justify-content: space-between;
  margin: 12px 20px 0px;
  align-items: center;
}
.sum__title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #EBEBEB;
}
.sum{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00FBF4;
}

.info__container{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
  margin: 32px 20px 0px;
}
.info{
  display: flex;
  justify-content: space-between;

}
.info__title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  width: 136px;
  text-align: start;
}
.info__date{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}