@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.button__back {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #ebebeb;
  background-color: transparent;
  border: none;
  display: flex;
  gap: 17px;
  margin: 25px 29px 0;
  margin-bottom: 24px;
}
