@import '../../styles/variables.scss';

.products {
  padding-bottom: 24px;
  &__list {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    list-style: none;
    gap: 12px;
  }

  img {
    display: inline-block;
    width: 90px;
  }

  &__item {
    display: flex;
    align-items: center;
    background-color: $secondaryBgColor;
    border-radius: 10px;
    gap: 24px;
    height: 76px;
    padding: 0 10px 0 10px;
    cursor: pointer;
  }

  &__arrow {
    background: url('./assets/arrowDown.svg');
    background-repeat: no-repeat;
    width: 40px;
    height: 20px;
    text-align: center;
    background-position: center;
    margin-left: auto;
  }

  &__arrow_down {
    transform: rotate(180deg);
  }

  &__show {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 16px;
    margin-top: 12px;
  }

  &__hide {
    display: none;
  }

  &__items {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 12px 5px 13px 5px;
    position: relative;

    img {
      display: inline-block;
      width: 70px;
      margin-right: 24px;
    }
  }

  &__items_sale {
    color: #828282;
  }

  &__price {
    margin-left: auto;
  }

  &__name {
    width: 48.5%;
  }

  &__disable {
    position: absolute;
    width: 335px;
    height: 100%;
    top: 0;
    left: -20px;
    background-color: rgba(45, 45, 46, 0.4);
    z-index: 10;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }

  &__line {
    border-bottom: 1px solid #828282;
    width: calc(100%);
    height: 1px;
  }

  &__exit {
    display: flex;
    align-items: center;
    padding: 18px 0 26px 0;
    justify-content: flex-end;
    cursor: pointer;
  }
}
