@import '../../styles/variables.scss';

.salary {
  display: flex;
  flex-direction: column;
  border-bottom: 0.3px solid $dividerColor;
  padding-top: 23px;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 4px;
  }
  &__title {
    margin: 15px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__total {
    font-weight: 700;
    font-size: 60px;
    color: $secondaryTextColor;
    margin-right: 27px;
    pointer-events: none;
    line-height: 110%;
  }
}

.progress {
  margin-top: 24px;

  &__container {
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
