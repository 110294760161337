@import '../../../styles/variables.scss';

.progress-ach {
  position: absolute;
  top: 7px;
  right: 6.5px;
  width: 61px;
  height: 61px;

  &__svg {
    width: 61px;
    height: 61px;
  }

  &__track {
    stroke: #9b9b9c;
    stroke-width: 2.5px;
    fill: transparent;
  }

  &__indicator {
    stroke: $progressBarSvgColor;
    stroke-width: 2.5px;
    fill: transparent;
  }
}
