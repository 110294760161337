.modal__container{
  align-items: center;
  background-color: rgba(32, 32, 33, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;
}
.modal {
  width: 335px;
  height: 274px;
  background: #2D2D2E;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.close{
  border: none;
  background-color: transparent;
  margin: 14px 14px 0px;
  justify-content: flex-end;
  display: flex;
}
.button{
  width: 295px;
  height: 39px;
  background: #00FBF4;
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #202021;
}
.butto__back{
  width: 295px;
  height: 39px;
  border: 2px solid #00FBF4;
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #00FBF4;
  background-color: transparent;
}
.submit{
  display: flex;
  margin: 24px 20px 0px;
  flex-direction: column;
  gap: 16px;
}
.title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  color: #EBEBEB;
  margin: 34px 20px 0px;
}