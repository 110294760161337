@import '../../../styles/variables.scss';

.content {
  padding: 12px 20px 24px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 12px;

  &__title {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  &__name {
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
  }

  &__close {
    position: absolute;
    background: transparent;
    border: none;
    color: $mainTextColor;
    cursor: pointer;
    right: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }

  &__description {
    display: flex;
    justify-content: space-between;
    gap: 9px;
  }

  &__image {
    width: 112px;
    height: 122px;
    margin-left: 4px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: -0.01em;
    text-align: left;
    padding-top: 28px;
  }

  &__total {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__flash {
    background: url(../assets/flash.svg);
    width: 19px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 5px;
  }

  &__coins {
    background: url(../assets/coins.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
  }

  &__muffin {
    background: url(../assets/muffin.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    margin-right: 6px;
  }

  &__list {
    background: url(../assets/checklist.svg);
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
  }

  &__data_list {
    cursor: pointer;
  }

  &__data_list:active {
    color: $secondaryTextColor;
  }

  &__block {
    display: flex;
    align-items: center;
    margin-right: 23px;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    letter-spacing: -0.01em;
  }

  &__subtitle {
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
  }

  &__data {
    width: 178px;
  }

  &__progress {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__done_active {
    color: $secondaryTextColor;
  }

  &__done_disable {
    color: $disabledColor;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}
