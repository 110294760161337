@import '../../styles/variables.scss';

.select {
  width: 122px;
  z-index: 1;
  position: relative;
  line-height: 110%;
  letter-spacing: -0.01em;

  &__value {
    width: 100%;
    padding: 0px 8px 0px 10px;
    height: 38px;
    box-sizing: border-box;
    background: $secondaryBgColor;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    line-height: 110%;
  }

  &__list {
    width: inherit;
    position: absolute;
    top: 45px;
    list-style: none;
    padding: 10px 10px 0px;
    background: $secondaryBgColor;
    border-radius: 10px;
    cursor: pointer;
    z-index: 100;
  }

  &__list_hide {
    visibility: hidden;
  }

  &__option {
    margin-bottom: 15px;
  }
}

.arrow {
  width: 15px;
  height: 7px;
  background-image: url('../../assets/arrow.svg');
  background-repeat: no-repeat;
  pointer-events: none;
}

.arrow_up {
  transform: rotate(0deg);
}

.arrow_down {
  transform: rotate(180deg);
}

.arrow_right {
  transform: rotate(270deg);
}

.arrow_left {
  transform: rotate(90deg);
}
