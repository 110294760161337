$mainTextColor: #ebebeb;
$secondaryTextColor: #00fbf4;
$mainBgColor: #202021;
$secondaryBgColor: #2d2d2e;
$dividerColor: rgba(255, 255, 255, 0.5);
$wrongTextColor: #d80000;
$backgroundProgressBar: #456b6a;
$grey: #ebebeb;
$coldGrey: #bdbdbd;
$white: #ffffff;
$blue: #007aff;
$disabledColor: #828282;
$progressBarSvgColor: #fd7801;
$achieveSvgNoColor: #9b9b9c;
