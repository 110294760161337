.bonus__container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bonus__left{
  display: flex;
  gap: 24px;
  align-items: center;
}
.bonus__p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #EEEEEE;
  width: 140px;
}
.bonus{
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
}
.cup{
  width: 24px;
  height: 24px;
  background: #00FBF4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: absolute;
  top: 0px;
}
.cupSpent{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: absolute;
  top: 0px;
  background: #BDBDBD;
}
.bonus__right{
  position: relative;
}
.bonus__points{
  width: 54px;
  height: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.2175) 100%);
  opacity: 0.4;
  border-radius: 35px;
}
.points{
  top: 4px;
  right: 4px;
  position: absolute;
  width: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #EEEEEE;
}
.bonus__pointsSpent{
  width: 54px;
  height: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.2175) 100%);
  opacity: 0.4;
  border-radius: 35px;
}
.pointsSpent{
  top: 4px;
  right: 4px;
  position: absolute;
  width: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #3D908E;
}
.img__bonus{
  width: 56px;
  height: 61px;
}