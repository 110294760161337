@import '../../styles/variables.scss';

.modal {
  height: 100vh;
  width: 375px;
  background-color: rgba($color: $mainBgColor, $alpha: 0.7);
  position: fixed;
  top: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 0.5s;
  pointer-events: none;
  z-index: 4;

  &__content {
    border-radius: 25px;
    transform: scale(0.5);
    transition: 0.5s;
  }

  &__content.active {
    transform: scale(1);
  }
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}
