.container{
margin: 24px 20px 25px;
}
.container__title{
  display: flex;
  gap: 10px;;
  margin-bottom: 21px;
  align-items: center;
}
.title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
}
.quantity__container{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 24px;
  height: 24px;
  background: #00FBF4;
  border-radius: 100px;
}
.quantity{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
}
.notifications__container{
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.container__list{
  border-bottom: 1px solid #828282;
  padding-bottom: 20px;
}
.text_rating{
  max-width: 285px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}
.text{
  width: 335px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}
.viewed{
  background: #202021;
  opacity: 0.3;
}
.date__info{
 display: flex;
 gap: 10px;
 margin-bottom: 12px;
}
.date__p{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #828282;
}
.name__p{
  font-family: 'SF UI Display';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
}
.details__container{
  display: flex;
  gap: 8px
}
.button{

}
.details{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.fine__container{
  display: flex;
  justify-content: space-between;
}