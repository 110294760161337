.fine__container {
  margin-top: 21px;
  img {
    height: 400px;
    width: 300px;
  }
}
.control-check__container {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
  .control-check__title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #00fbf4;
  }
  .control-check__date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    display: flex;
    align-items: center;
    color: #ebebeb;
  }
}
.violations__container{
  margin: 20px 20px 12px;
  .violations__header{
    display: flex;
    justify-content: space-between;
  }
}
.violations__title,
.violations__sum {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #ffffff;
}
.violations__sum {
  width: 116px;
}
.violations__title.header,
.violations__sum.header {
  font-weight: 600;
  line-height: 110%;
  color: #ebebeb;
}
.violations__final-sum {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.violations__sum.final {
  color: #d80000;
}

.fine__button-details{
  padding: 0px 8px;
  margin-top: 24px;
  width: 335px;
  height: 29px;
  background: #2d2d2e;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  color: #ebebeb;
  letter-spacing: -0.01em;
  position: relative;
}
.fine__button-svg {
}

.details-slider__container {
  overflow: hidden;
  width: 355px;
}
.details-slider {
  width: auto;
  left: 0px;
  position: relative;
  display: flex;
  gap: 10px;
}
.checking{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #ebebeb;
  margin-top: 12px;
  margin-bottom: 48px;
  margin-left: 20px;
}
