.offers__container{
  display: flex;
  flex-direction: column;
  margin: 25px 0px;
}
.offers__title__container{
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
}
.offers__title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}
.offers__goBack{
  background-color: transparent;
  border: none;
}
.offers__info__container{
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 13px 20px;
}
.offers__info{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.9);
}
.offers__info__title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #E9E9E9;
}
.form__container{
  margin-top: 3px;
}
.form__textarea{
  margin: 0px 20px;
  position: relative;
  width: 335px;
  height: 193px;
  background: #2D2D2E;
  border-radius: 20px;
  border: none;
  outline:none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #FFFFFF;
  caret-color: #007AFF;
  padding: 10px;
}
.form__list{
  display: flex;
  align-items: center;
  margin-left: 12px;
  margin-right: 8px;
}
.form__list__file{
  margin-left: 8px;
  position: relative;
  margin-top: 28px;
  display: flex;
  align-items: center;
}
.input__file{
  display: none;
}
.addFile__p{
  margin-left: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}
.svg__img{

}
.form__list__text{
  position: relative;
}
.form__span{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #828282;
  position: absolute;
  bottom: -15px;
  right: 30px;
}
.submit{
  width: 335px;
  height: 39px;
  background: #00FBF4;
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #202021;
}
.submit__anonymous{
  width: 335px;
  height: 39px;
  border: 2px solid #00FBF4;
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #00FBF4;
  background-color: transparent;
}
.submit:disabled{
  background: #BDBDBD;
  color: #828282;
}
.submit__anonymous:disabled{
  color: #828282;
  background: rgba(189, 189, 189, 0.3);
  border: 2px solid #828282;
}
.form__button{
  display: flex;
  gap: 14px;
  flex-direction: column;
  margin: 45px 20px 0px;
}
.error__span{
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #FC321D;
  position: absolute;
  bottom: -17px;
  left: 21px;
}
.error__span__file{
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #FC321D;
  position: absolute;
  bottom: -21px;
  left: 14px;
}