@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.review__container{
  width: 335px;
  min-height: 32px;
  background: #2D2D2E;
  border-radius: 10px;
  padding-top: 7.5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16.5px;
}
.review__container__details{
  width: 335px;
  min-height: 32px;
  background: #2D2D2E;
  border-radius: 10px;
  padding-top: 11.5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16.5px;
  padding-bottom: 8px;
  margin-bottom: -8px;
}
.review__maim__container__details{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 66px;
}
.review__maim__container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 79px;
}
.styles.review__main{
  width: 335px;
  display: flex;
}
.review__date,
.review__main,
.review__review{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}
.review__review{
  margin: 0px 14px 0px 8px;
}
.fine__button-svg{
}
.list__container{
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: space-between;
}
.item{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 330px;
}
.details__title{
  text-align: left;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  max-width: 72px;
}
.details__title.details{
  max-width: 300px;
}
.details__p{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  width: 248px;
}
.review__date__details{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
}
.review__review__details{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  margin: 0px 12px 0px 8px;
}
.details__review{
  color: #00FBF4;
}