.fine-img{
    height: 400px;
    width: 300px;
}
.file{
  .swiper {
    width: 100%;
    height: 100%;
    .swiper-wrapper{
      align-items: center;
      .swiper-slide{
        width: 80px;
        margin-right: 5px;
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        img {
          display: block;
          width: 80px;
          height: 80px;
          object-fit: cover;
        }
      }
      .swiper-slide:nth-child(1) {
        margin-left: 0px; 
      }
      .swiper-slide:nth-last-child(1) {
        margin-right: 10px;
      }
    }
  } 
}
