.award {
  border-radius: 35px;
  display: flex;
  align-items: center;
  line-height: 110%;

  &__img {
    background-repeat: no-repeat;
    margin-right: 4px;
  }
}
