.mySwiper {
  .fine-img {
    height: 400px;
    width: 300px;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-wrapper {
    align-items: center;
    .swiper-slide {
      width: 300px;
      margin-right: 10px;
    }
  }

  .swiper-slide:nth-child(1) {
    margin-left: 20px;
  }
  .swiper-slide:nth-last-child(1) {
    margin-right: 20px;
  }
}
