.calendar__container{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-row-gap: 12px;
  grid-column-gap: 9px;
}
.days__week{
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-bottom: 16px;
}
.days__title{
  width: 40px;
  height: 18px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}