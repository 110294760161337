@import '../../../styles/variables.scss';

.achieve {
  width: 76px;
  height: 78.81px;
  position: relative;

  &__circle {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 76px;
    height: 78.81px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    .circle__svg {
      width: 64px;
      height: 64px;
    }

    .circle__track {
      stroke: $achieveSvgNoColor;
      stroke-width: 3px;
      fill: transparent;
    }

    .circle__indicator {
      stroke: $progressBarSvgColor;
      stroke-width: 3px;
      fill: transparent;
    }
  }

  &__line {
    position: absolute;
    bottom: -11px;
    left: 7px;
  }

  &__image {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 76px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    img {
      height: 47px;
    }
  }
}
