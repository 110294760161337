.medal {
  border-radius: 35px;
  display: flex;
  align-items: center;
  line-height: 110%;
  font-size: 12px;

  &__img {
    background: url(./assets/cup.svg);
    background-repeat: no-repeat;
    margin-right: 2px;
  }
}
