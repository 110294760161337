@import '../../app.scss';

.footer {
  height: 180px;
  padding: 24px 20px 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: 'SF UI Display', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  border-top: 0.3px solid $dividerColor;

  .footer-company {
    font-family: 'Sen', sans-serif;
    font-weight: 700;
    font-size: 20px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: 24px;
    color: $secondaryTextColor;
  }

  a {
    outline: none;
    text-decoration: none;
    color: $mainTextColor;
    margin: 0;
    padding: 0;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    height: 130px;
    justify-content: space-between;
  }
}
