.loader {
  margin-top: 40px;
  width: 100px;
  height: 100px;
  border: 2px dashed rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  animation: rotate 1s infinite linear;
  z-index: 1000;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
