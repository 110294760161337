@import '../../styles/variables.scss';

.category {
  padding-top: 27px;
  padding-bottom: 28px;

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
    list-style: none;
    gap: 12px;
  }

  // img {
  //   display: inline-block;
  //   width: 90px;
  // }

  &__item {
    display: flex;
    height: 92px;
    // align-items: center;
    background-color: $secondaryBgColor;
    border-radius: 10px;
    gap: 24px;
    // height: 76px;
    padding: 10px 20px 12px 20px;
    cursor: pointer;
  }

  &__bonus {
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.01em;
  }

  &__arrow {
    background: url('./assets/arrowDown.svg');
    background-repeat: no-repeat;
    width: 40px;
    height: 20px;
    text-align: center;
    background-position: center;
    margin-left: auto;
    align-self: center;
  }

  &__arrow_down {
    transform: rotate(180deg);
  }

  &__show {
    display: flex;
    flex-direction: column;
    list-style: none;
    font-size: 16px;
    margin-top: 12px;
  }

  &__hide {
    display: none;
  }

  &__items {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-bottom: 1px solid rgba(92%, 92%, 92%, 0.1);

    img {
      display: inline-block;
      width: 70px;
      margin-right: 24px;
    }
  }

  &__name {
    margin-bottom: 8px;
  }

  &__name_item {
    font-size: 16px;
  }

  &__disable {
    position: absolute;
    width: 375px;
    height: 100%;
    top: 0;
    left: -20px;
    background-color: rgba(45, 45, 46, 0.4);
    z-index: 10;
    display: flex;
    align-items: center;
    padding-left: 20px;
  }

  &__line {
    border-bottom: 1px solid #828282;
    width: calc(100% - 20px);
    height: 1px;
  }

  &__exit {
    display: flex;
    align-items: center;
    padding: 18px 0 26px 0;
    justify-content: flex-end;
    cursor: pointer;
  }
}
