.container{
  position: absolute;
  top: 56px;
  left: 0px;
  right: 0px;
  width: 375px;
  background: #202021;
  margin: 0px auto;
  z-index: 100;
}
.button__container{
  margin: 24px 20px 40px;
  display: flex;
  gap: 21px;
  align-items: center;
  cursor: pointer;
}
.button__titile{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
}
.container__monitor{
  display: flex;
  gap: 41px;
  justify-content: center;
}
.container__info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(235, 235, 235, 0.2);
}
.day__format{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}
.info__time__container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin: 30px 20px 0px;
  
}
.info__time{
  display: flex;
  justify-content: space-between;
}
.info__time__p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #EBEBEB;
}
.p__blue{
  color: #00FBF4;
}
.achievements__container{
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(235, 235, 235, 0.2);
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 20px 0px;
}
.achievements{
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    height: 37px;
  }
}
.achievements__p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
}
.bonus__right{
position: relative;
}
.cup{
  width: 24px;
  height: 24px;
  background: #00FBF4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: absolute;
  top: 0px;
  img{
    width: 14px;
    height: 14px;
  }
}
.bonus__points{
  width: 54px;
  height: 24px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.2175) 100%);
  opacity: 0.4;
  border-radius: 35px;
}
.points{
  top: 4px;
  right: 4px;
  position: absolute;
  width: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #EEEEEE;
}
.total__container{
  margin: 24px 20px 50px;
}
.total{
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.total__list{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total__titile__container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.total__h2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.blue{
  color: #00FBF4;
}
.total__h3{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.red{
  color: #D80000;
}
.total__h4{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
}
.total__w1{
  width: 116px;
}
.total__w2{
  width: 20px;
}
.total__w3{
  width: 70px;
  text-align: right;
  display: block;
}
.total__w4{
  width: 69px;
  text-align: right;
  display: block;
}
.categories{
  display: flex;
  align-items: center;
  gap: 20px;
}
.categories__container{
  gap: 9px;
  display: flex;
  flex-direction: column;
}