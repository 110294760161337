.button__received{
  width: 108px;
  height: 44px;
  background: #2D2D2E;
  border-radius: 12px 0px 0px 12px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  border: none;
  justify-content: center;
}
.button__all{
  width: 111px;
  height: 44px;
  background: #2D2D2E;
  border-radius: 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  border: none;
  justify-content: center;
}
.button__spent{
  width: 108px;
  height: 44px;
  background: #2D2D2E;
  border-radius: 0px 12px 12px 0px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  justify-content: center;
  border: none;
}
.button__container{
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 24px;
  
}

.scroll{
  height: 40vh;
  overflow-y: auto;
  margin: 0px 10px;
}
::-webkit-scrollbar{
  width: 4px;
}
  
::-webkit-scrollbar-thumb{
  background: #8B8B8B; 
  border-radius: 27px;
  border: 4px solid rgba(0, 0, 0, 0);
 }
.scroll::-webkit-scrollbar:horizontal{
  width: 0;
  height: 0;
}
.reviewsAll__container{
  display: flex;
  gap: 15px;
  flex-direction: column;
  margin: 0px 5px 0px 10px;
} 
.active{
  background: #00FBF4;
  color: #202021;
}