.violations__list{
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.violations__title,
.violations__sum {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.violations__sum{
  width: 116px;
}