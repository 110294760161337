@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
$grey: #ebebeb;
$grey2: #bdbdbd;
$red: #fc321d;
$blue: #007aff;
.logo {
  margin-top: 15px;
  margin-bottom: 100px;
}
.authorization__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0px;
  left: 0px;
  background: #202021;
  width: 375px;
  z-index: 100;
  min-height: 100vh;
}
.authorization__title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  color: $grey;
}
.form__container {
  margin-top: 77px;
}
.form__list {
  position: relative;
}
.password {
  margin-top: 65px;
}
input {
  outline: none;
  border: none;
  width: 295px;
  height: 28px;
  border-bottom: 0.5px solid $grey2;
  background-color: transparent;
  caret-color: $blue;
  color: $grey;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}
.authorization__label {
  position: relative;
}
.button__password {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  top: -4px;
}
.label__span {
  position: absolute;
  left: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: $grey2;
  top: -15px;
}
input:focus + .label__span,
input:not(:placeholder-shown) + .label__span {
  top: -22px;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #f2f2f2;
  transition: all 0.2s ease-in-out;
}
.remember label {
  display: flex;
  margin-top: 24px;
  align-items: center;
  gap: 4px;
  justify-content: flex-end;
  input {
    width: 11px;
    height: 11px;
  }
  .remember__p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #828282;
  }
}
.email__clue {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #828282;
  position: absolute;
  top: 28px;
}
.submit {
  margin-top: 60px;
  margin-bottom: 10px;
  width: 295px;
  height: 39px;
  background: #00fbf4;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #202021;
  padding: 0;
}
.submit:disabled {
  color: $grey;
  background: #2d2d2e;
}
.authorization__error {
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: $red;
  position: absolute;
  top: 32px;
}
