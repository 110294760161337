.title__container{
  margin: 22px 20px 0px;
}
.bonuses__container{
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: center;
  margin: 24px 0px;
  img{
    width: 93px;
    height: 93px;
  }
}
.title__h1{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
}
.info__p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
}
.info__points{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #00FBF4;
  justify-content: center;
}
.bonuses__info{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
}