.button{
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.timesheet__container{
  display: flex;
  gap: 49px;
  margin: 30px 20px 26px;
  justify-content: center;
}
.button__change{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  height: 37px;
  border: none;
  background-color: transparent;
  padding: 0px 10px;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}
.active{
  border-bottom: 1px solid #EBEBEB;
}
.button__change__container{
  display: flex;
  gap: 28px;
  margin-top: 10px;
}
.button__change__today{
  width: 90px;
  display: flex;
  gap: 13px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.selection__box__container{
  position: fixed;
  height: 100vh;
  top: 0px;
  right: 0px;
  height: 100vh;
  left: 0px;
  display: flex;
  justify-content: center;
  background: rgba(45, 45, 46, 0.7);
  z-index: 100;
}
.selection__box{
  width: 335px;
  height: 246px;
  background: #202021;
  border-radius: 10px;
  position: fixed;
  z-index: 1000;
  margin-top: 160px;
}
.button__container__box{
  display: flex;
  justify-content: space-between;
  margin: 29.5px 20px 0px;
}
.button__box{
  width: 20px;
  cursor: pointer;
}
.month__container{
  margin: 22.5px 58px 0px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 12px;
  grid-column-gap: 20px;
}
.button__month{
  cursor: pointer;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #EBEBEB;
  width: 40px;
  height: 40px;
  border: 1px solid #2D2D2E;
  border-radius: 10px;
}