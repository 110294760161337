
.file__container{
  display: flex;
  position: relative;
}
.svg{
  position: absolute;
  top: 4px;
  left: 4px;
}
.file__img{
  width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: cover;
}
.modal__container{
  align-items: center;
    background-color: rgba(45, 45, 46, 0.7);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
}
.modal {
  background-color: #202021;
  border-radius: 10px;
  padding: 68px 0px;
  width: 375px;
  display: flex;
  align-items: center;
  justify-content: center;
    img{
      max-width: 350px;
      max-height: 60vh;
    }
    position: relative;
}
.button__close{
  border: none;
  background-color: transparent;
  position: absolute;
  top: 26px;
  right: 26px;
}