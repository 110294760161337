.homepage__container{
  position: absolute;
  width: 375px;
  background: #202021;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  img{
    margin-top: 75px;
    width: 161px;
    height: 161px;
  }
}
.title{
  margin-top: 80px;
  font-family: 'Sen';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #00FBF4;
}
.button__container{
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
  margin-bottom: 207px;
}
.button{
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 295px;
  height: 39px;
  background: #00FBF4;
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #202021;
}
.none{
  display: none;
}