@import '../../../styles/variables.scss';

.possibility {
  padding-top: 30px;
  padding-bottom: 20px;

  &__header {
    display: flex;
    gap: 15px;
    padding-left: 7px;
    padding-bottom: 33px;

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
    }
  }

  &__back {
    width: 20px;
    height: 20px;
    background-image: url('../assets/arrowBack.svg');
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .bands {
    width: 60%;
    height: 2px;
    background-color: transparent;
    display: flex;
    margin: 12px auto 0px;
    gap: 8px;
  }

  .band {
    height: 2px;
    background-color: $coldGrey;
  }

  .band.selected {
    background-color: $secondaryBgColor;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    margin-bottom: 8px;
    align-self: flex-start;
  }

  &__title_coach {
    margin-left: 20px;
  }

  &__advantages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__advantage {
    list-style-position: inside;
    font-size: 16px;
    line-height: 120%;
  }

  &__programm {
    list-style-position: inside;
    font-size: 14px;
    line-height: 140%;
    letter-spacing: -0.01em;
  }
  &__text {
    font-size: 16px;
    line-height: 120%;
    padding-right: 10px;
    text-align: left;
  }

  &__photo {
    width: 317px;
    height: 266px;
    background-repeat: no-repeat;
    margin: 0 auto;
    margin-bottom: 12px;
  }

  &__words {
    line-height: 140%;
    letter-spacing: -0.01em;
    display: flex;
    padding: 0 35px 0 30px;
  }

  &__quotesL {
    font-size: 60px;
    text-align: start;
    height: 20px;
    align-self: flex-start;
    margin-right: 8px;
  }

  &__cost {
    display: flex;
    justify-content: space-between;
    line-height: 140%;
  }

  &__bonuses {
    margin-bottom: 12px;
  }

  &__payment {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 120%;
    gap: 12px;
  }

  &__payment_cost {
    color: #f2994a;
    font-weight: 600;
    font-size: 20px;
    line-height: 110%;
  }

  &__btn {
    background: $secondaryTextColor;
    border-radius: 20px;
    width: 295px;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    padding: 9px 0 12px 0;
    margin: 16px 0 0 20px;
    cursor: pointer;
    color: $mainBgColor;
  }

  &__btn:active {
    transition: 0.3s all;
    transform: scale(0.9);
  }
}

.modal__wrapper {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal__close {
  position: absolute;
  background: transparent;
  border: none;
  color: $mainTextColor;
  cursor: pointer;
  right: 30px;
  top: 30px;
  display: flex;
  align-items: center;
  text-align: center;
}

.modal__header {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  width: 220px;
  margin-bottom: 24px;
  text-align: center;
}

.modal__text {
  margin-top: 20px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  width: 219px;
}
