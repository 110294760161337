@import '../../styles/variables.scss';

.profile {
  display: flex;
  flex-direction: column;
  padding: 0 20px 20px 20px;
  align-items: center;
}

.profileTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-self: flex-start;
  color: $white;
}

.profileWrapper {
  width: 335px;
  margin-top: 22px;
  background: $secondaryBgColor;
  border-radius: 20px;
  padding: 20px;
  position: relative;

  h4 {
    margin-bottom: 17px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: $secondaryTextColor;
  }

  h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: $grey;
    margin-top: 6px;
  }
}

.profilePhoto {
  display: none;
}

.pen {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.position {
  display: flex;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: $secondaryTextColor;
  padding-bottom: 7px;

  p {
    margin-right: 8px;
  }
}

input {
  outline: none;
  border: none;
  width: 295px;
  height: 32px;
  border-bottom: 0.5px solid $coldGrey;
  background-color: transparent;
  caret-color: $blue;
  color: $grey;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.form__list {
  position: relative;
  display: flex;
  align-items: center;
  width: 80px;
  height: 80px;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

.profilePhotos {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.bands {
  width: calc(100% - 8px);
  height: 2px;
  background-color: transparent;
  display: flex;
  position: absolute;
  top: 8px;
  left: 4px;
  z-index: 10;
  gap: 5px;
}

.band {
  height: 2px;
  background-color: $coldGrey;
}

.band.selected {
  background-color: $secondaryBgColor;
}

.swiper__wrapper {
  position: relative;
}

.wrapper {
  position: absolute;
  bottom: 16px;
  right: 2px;
  display: flex;
  gap: 8px;
  z-index: 4;
}

.load,
.check,
.trash {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $secondaryBgColor;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.load {
  background-image: url('./assets/camera.svg');
}

.check {
  background-image: url('./assets/check.svg');
}

.trash {
  background-image: url('./assets/trash.svg');
}

.title__wrapper {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 27px;
  margin-bottom: 20px;
}

.close__icon {
  display: inline-block;
  align-items: flex-end;
  margin-top: 3px;
  margin-left: auto;
  cursor: pointer;
}
