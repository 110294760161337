@import '../../styles/variables.scss';

.progressbar-line {
  height: 7px;
  width: 100%;
  background-color: $backgroundProgressBar;
  border-radius: 40px;
  position: relative;

  &__child {
    height: 100%;
    background-color: $secondaryTextColor;
    border-radius: 40px;
    text-align: right;
  }

  &__text {
    font-size: 12px;
    line-height: 120%;
    color: $mainTextColor;
    font-weight: 400;
    position: absolute;
    top: -18px;
  }
}
