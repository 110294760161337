@import '../../styles/variables.scss';

.achievements {
  padding-top: 20px;

  &__title {
    letter-spacing: 1px;
    line-height: 110%;
    margin-bottom: 25px;
  }

  &__list {
    padding-right: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__item {
    position: relative;
    width: 160px;
    height: 227px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 18px 0 24px 0;
    border: 1px solid $secondaryBgColor;
    border-radius: 20px;
    margin-bottom: 13px;
    cursor: pointer;
  }

  &__img {
    width: 112px;
    height: 122px;
  }

  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    padding-top: 11px;
    padding-bottom: 10px;
  }

  &__progress {
    width: 114px;
    justify-self: flex-start;
  }

  &__count {
    position: absolute;
    right: 14px;
    top: 12px;
    border: 1px solid $secondaryTextColor;
    background: $mainBgColor;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondaryTextColor;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    z-index: 3;
  }

  &__count_show {
    display: flex;
  }

  &__count_hide {
    display: none;
  }
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid $achieveSvgNoColor;
}
