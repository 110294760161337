.stars__container{
  position: relative;
  display: flex;
  gap: 4px;
  width: 86px;
  height: 14.17px;
}
.line{
  //width: 100%;
  height: 20px;
  overflow: hidden;
}
.svg__grey{
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  gap: 4px;
  .svg{
    fill: #696969;
  }
}
.svg__gold{
  position: relative;
  top: 0px;
  left: 0px;
  display: flex;
  gap: 4px;
  width: 86px;
  svg{
    fill: #F2994A;
  }
}