.modal__container {
  align-items: center;
  background-color: rgba(32, 32, 33, 0.7);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  width: 100%;
  height: 100vh;
}
.modal {
  width: 335px;
  height: 274px;
  background: #2d2d2e;
  border-radius: 10px;
  margin: 20vh auto 0px;
  display: flex;
  flex-direction: column;
}
.button__close {
  border: none;
  background: transparent;
  margin: 14px 14px 0px;
  display: flex;
  justify-content: flex-end;
}
.title {
  margin-top: 34px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  text-align: center;
  color: #ebebeb;
}
.button__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;
  align-items: center;
}
.button__cancel {
  border: none;
  background: transparent;
  width: 295px;
  height: 39px;
  background: #00fbf4;
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #202021;
}
.button__exit {
  border: none;
  background: transparent;
  width: 295px;
  height: 39px;
  border: 2px solid #00fbf4;
  border-radius: 20px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 110%;
  color: #00fbf4;
}
