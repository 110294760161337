@import '../../styles/variables.scss';

.header {
  padding: 0 20px 0 25px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $grey;
}

.logo {
  margin: 7px 0 0 85px;
  width: 108px;
  height: 50px;
  cursor: pointer;
}

.user {
  margin-left: 34px;
  margin-top: 8px;
  position: relative;
  cursor: pointer;
}

.userPhoto {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.menuIcon {
  position: absolute;
  z-index: 1;
  left: 30px;
  bottom: 4px;
}

.hamburger {
  cursor: pointer;
}

.close {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
