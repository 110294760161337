.rating__page{
  margin-top: 23px;
}
.rating__container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px 20px;
}
.rating__h2{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EEEEEE;
}
.reviews__amount__container{
  display: flex;
  gap: 8px;
  margin: 15px 20px 12px;
  p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #EEEEEE;
  }
}
.reviews__amount__container.black{
  opacity: 0.3;
}
.scroll{
  height: 40vh;
  overflow-y: auto;
  margin: 0px 10px;
}
::-webkit-scrollbar{
  width: 4px;
}
  
::-webkit-scrollbar-thumb{
  background: #8B8B8B; 
  border-radius: 27px;
  border: 4px solid rgba(0, 0, 0, 0);
 }
.scroll::-webkit-scrollbar:horizontal{
  width: 0;
  height: 0;
}
.reviewsAll__container{
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin: 0px 8px;
} 