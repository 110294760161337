.possibilitySwiper {
  position: relative;

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    height: 175px;
    object-fit: cover;
  }

  .swiper-wrapper {
    align-items: center;
  }
}
