@import '../../styles/variables.scss';

.menu {
  position: absolute;
  z-index: 1000;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba($color: $mainBgColor, $alpha: 0.7);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: $grey;

  ul {
    list-style-type: none;
  }
}

.wrapper {
  width: 300px;
  height: 650px;
  padding: 56px 0 50px 24px;
  background-color: $mainBgColor;
  z-index: 2;
}

.menuWrapper {
  display: flex;
  margin-top: 18px;

  &:hover {
    background: $secondaryBgColor;
  }
}

.menuTitle {
  margin-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.languages {
  display: flex;
  margin-top: 40px;
}

.languagesWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 46px;
  align-items: center;
  height: 58px;

  &:hover {
    color: $secondaryTextColor;
    border-bottom: 1px solid $secondaryTextColor;
  }
}

.languageIcon {
  width: 28px;
  height: 20px;
  cursor: pointer;
}

.languageTitle {
  margin-top: 10px;
}
