@import '../../../styles/variables.scss';

.progress-bar {
  position: relative;
  width: 92px;
  height: 92px;

  &__svg {
    width: 92px;
    height: 92px;
  }

  &__track {
    stroke: $backgroundProgressBar;
    stroke-width: 13px;
    fill: transparent;
  }

  &__indicator {
    stroke: $secondaryTextColor;
    stroke-width: 13px;
    fill: transparent;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $mainTextColor;
  }

  &__progress {
    font-size: 14px;
    font-weight: 400;
    color: $mainTextColor;
    display: block;
  }
}
