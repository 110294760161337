.list__h3{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
  width: 208px;
}
.list{
  display: flex;
  gap: 12px;
}
.list__container{
  gap: 12px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.list__blue{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #00FBF4;
}
.list__violet{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #978AFA;
}
.list__p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #EBEBEB;
}
.list__orange{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #F2994A;
}
button{
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.details__container{
  display: flex;
  gap: 11px;
  flex-direction: column;
  margin-top: 9.5px;
}
.details__list{
  display: flex;
  gap: 12px;
}
.details__list__h3{
  width: 208px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}
.details__list__p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}
.fines{
  color: #D80000;
}
.description__info__container{
  margin-top: 24px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.description__info__list{
  display: flex;
  gap: 8px;
  align-items: center;

}
.description__info__p{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #EBEBEB;
}