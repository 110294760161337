@import '../../styles/variables.scss';

.possibility {
  padding-top: 18px;
  padding-bottom: 23px;

  &__title {
    letter-spacing: 1px;
    line-height: 110%;
    margin-bottom: 20px;
  }

  &__btns {
    display: flex;
    gap: 5px;
  }

  &__order,
  &__history {
    width: 165px;
    height: 38px;
    background: #2d2d2e;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    color: $mainTextColor;
  }

  &__order {
    border-radius: 10px 0px 0px 10px;
  }

  &__history {
    border-radius: 0px 10px 10px 0px;
  }

  &__btn_active {
    background-color: $secondaryTextColor;
    color: $mainBgColor;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 12px;
  }

  &__item {
    display: flex;
    gap: 12px;
    // background: #363637;
    // border-radius: 10px;
    cursor: pointer;
    position: relative;
    padding: 0px 20px 13px 20px;
    border-bottom: 1px solid #828282;
  }

  &__item:last-child {
    border-bottom: none;
  }
  &__image {
    background-repeat: no-repeat;
    background-position: center;
    width: 107px;
    height: 120px;
  }

  &__name {
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 8px;
    margin-top: 12px;
  }

  &__awards {
    width: 57px;
    background: $mainBgColor;
    opacity: 0.8;
    border-radius: 35px;
  }

  &__description {
    margin-bottom: 24px;
    line-height: 140%;
    letter-spacing: -0.01em;
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    gap: 66px;
  }

  &__buy {
    color: $secondaryTextColor;
    font-size: 16px;
    line-height: 120%;
  }

  .history__wrapper {
    display: flex;
    gap: 12px;
    line-height: 120%;
    padding-bottom: 12px;
    border-bottom: 1px solid #828282;
  }

  .history__wrapper:last-child {
    border-bottom: none;
  }

  .history__info {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .history__title {
    max-width: 137px;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    cursor: pointer;
    color: $secondaryTextColor;
  }

  .history__payment {
    display: flex;
    align-items: flex-end;
    width: 216px;
    justify-content: space-between;
  }

  .history__status {
    width: 141px;
    height: 24px;
    font-size: 16px;
    background: $secondaryBgColor;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .history__img {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
