@import '../../../styles/variables.scss';

.salary-details {
  .salary-details__wrapper {
    padding: 24px 0 16px 0;
    font-size: 16px;
    border-bottom: 0.3px solid $dividerColor;
  }

  .salary-details__total-wrapper {
    padding: 20px 0 13px 0;
    font-size: 16px;
    border-bottom: 0.3px solid $dividerColor;
  }

  &__title {
    margin-bottom: 12px;
    font-weight: 700;
  }

  &__title,
  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__row {
    margin-bottom: 12px;
  }

  &__row_mark &__value {
    color: $secondaryTextColor;
  }

  &__row_fine &__value {
    color: $wrongTextColor;
  }

  &__row_total {
    margin-bottom: 8px;
  }

  &__indicator {
    width: 60%;
    margin-right: 20px;
  }

  &__revenue {
    font-weight: 300;
    font-size: 12px;
    margin-bottom: 4px;
  }

  &__button {
    width: 123px;
    height: 27px;
    border-radius: 10px;
    border: none;
    color: inherit;
    text-align: center;
    display: flex;
    font-size: inherit;
    align-items: center;
    padding: 0 8px;
    justify-content: space-between;
    cursor: pointer;
    text-decoration: none;
  }

  &__button:active {
    transition: 0.3s all;
    transform: scale(0.9);
  }

  &__value {
    width: 78px;
    text-align: right;
  }

  &__cup {
    width: 78px;
    display: flex;
    justify-content: center;
    margin-left: 6px;
  }
}

.salary-details_hidden {
  display: none;
}

.salary-details_visible {
  display: block;
}
