@import '../../../styles/variables.scss';

.slider {
  display: flex;
  align-items: center;
  padding: 25px 54px 8px 53px;
  gap: 55px;

  &__block {
    width: 76px;
    height: 80px;
    position: relative;
    cursor: pointer;
  }

  &__image {
    width: 76px;
    height: 80px;
    position: relative;
  }

  &__count {
    position: absolute;
    right: -19px;
    top: -6px;
    border: 1px solid $secondaryTextColor;
    background: $mainBgColor;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $secondaryTextColor;
    font-weight: 600;
    font-size: 16px;
    line-height: 110%;
    z-index: 3;
  }

  &__count_show {
    display: flex;
  }

  &__count_hide {
    display: none;
  }

  &__arrow {
    width: 24px;
    height: 24px;
    margin-top: 23px;
    background: url('./assets/arrow-sl.svg');
    background-repeat: no-repeat;
    cursor: pointer;
  }

  &__arrow_right {
    transform: rotate(180deg);
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
  }

  &__bar {
    width: 215px;
    justify-self: flex-start;
  }

  &__progress {
    width: 215px;
    align-self: flex-start;
    margin-left: 56px;
  }
}
