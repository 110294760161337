.timesheet__container{
  margin: 0px 20px 24px;
  
}
.timesheet__title{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  color: #EBEBEB;
  margin-top: 25px;
}