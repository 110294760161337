@import './styles/variables.scss';

@font-face {
  font-family: 'SF UI Display';
  src: url('./fonts/sf-ui-display.otf');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

// html {
//   background-color: #202021;
// }

body {
  font-family: 'Roboto';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

header {
  height: 56px;
}

.App {
  max-width: 375px;
  margin: 0 auto;
  background-color: $mainBgColor;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  color: $mainTextColor;
  position: relative;
}

.main {
  flex: 1 1 auto;
  min-height: calc(100vh - 236px);

  .title {
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
  }
}

.container {
  padding: 0 20px;
}
